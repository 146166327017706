import React from 'react';

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8007/backend/rechargedsolarpanels/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: text
function ElementHeaderH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="content-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="content-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: percentage
function ElementStatus(props)
{
    return (
        <React.Fragment>
            <div className="status-section">
                <div className="status-overlay" style={{width: props.percentage+'%'}}></div>
            </div>
        </React.Fragment>
    );
}

export { ElementDebugResponsive, ElementHeaderH1, ElementStatus }