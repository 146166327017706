import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

import './css/library/bootstrap.min.css';
import './css/body.css';
import './css/content.css';
import './css/debug.css';
import './css/form.css';
import './css/navbar.css';
import './css/status.css';

import { PageCalendar, PageContact, PageEstimate, PageInverter, PageOverview, PageMeterbox, PageNumber, PageRooftype, PageSolarpanel, PageStart, PageThankyou, PageType } from './components/Page';

import { SectionNavigation } from './components/Section';

import { ElementDebugResponsive } from './components/Element';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function App()
{
    // Debug
    const debug = useRef(false);

    // IP address
    const ip_address = useRef('https://thissen.ai');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        ip_address.current = (window.location.origin).replace('3005', '8007');
        console.log('IP: '+ip_address.current);

        debug.current = true;
    }

    // Upload icons
    const upload_icons = useRef('https://zonnepanelen.recharged.nl');

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        upload_icons.current = 'http://localhost:8007';

        if (debug.current === true)
        {
            console.log('Upload folder: '+upload_icons.current);
        }
    }

    // Upload images
    const upload_images = useRef('https://zonnepanelen.recharged.nl');

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        upload_images.current = 'http://localhost:8007';

        if (debug.current === true)
        {
            console.log('Upload folder: '+upload_images.current);
        }
    }

    // Translations
    const [translations_nl, translations_nl_set] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/rechargedsolarpanels/api/translations');
                // <str> key
                // <str> language_nl

                // Build dictionary
                let dict = {};
                for (let i = 0; i < response.data.length; i++)
                {
                    dict[response.data[i][0]] = response.data[i][1];
                }

                translations_nl_set(dict);

                if (debug.current === true)
                    console.log(dict);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [debug]);

    // Metatag
    const [metatag_data, metatag_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/rechargedsolarpanels/api/metatag');
                // <array>   
                // <str> slug
                // <str> meta_title
                // <str> meta_description
                // <bool> noindex

                metatag_set_data(response.data);
                
                if (debug.current === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, []);

    const [meta_title, meta_set_title] = useState('');
    const [meta_description, meta_set_description] = useState('');
    const [meta_noindex, meta_set_noindex] = useState(false);

    function AppMetatag(props)
    {
        const location = useLocation();

        useEffect(() => {
            if (props.metatag_data !== null)
            {
                for (let i = 0; i < props.metatag_data.length; i++)
                {
                    let match = matchPath({path:props.metatag_data[i][0], strict:true}, location['pathname']);
                    if (match !== null)
                    {
                        meta_set_title(props.metatag_data[i][1]);
                        meta_set_description(props.metatag_data[i][2]);
                        meta_set_noindex(props.metatag_data[i][3]);

                        if (props.debug === true)
                        {
                            console.log('Meta title: '+props.metatag_data[i][1]);
                            console.log('Meta description: '+props.metatag_data[i][2]);
                            console.log('Meta noindex: '+props.metatag_data[i][3]);
                        }

                        break;
                    }
                }
            }
        }, [props.debug, props.metatag_data, location]);

        return null;
    }

    // Scroll to top
    function AppScrollToTop()
    {
        const location = useLocation();

        useEffect(() => {
            if (location['search'] === '' && location['hash'] === '')
            {
                window.scrollTo({
                    top: 0,
                    behavior: 'instant',
                });
            }
        }, [location]);
        
        return null;
    }

    // Percentage
    const [percentage, percentage_set] = useState(0);
    
    function AppPercentage(props)
    {
        const location = useLocation();
        const function_percentage_set = props.function_percentage_set;

        useEffect(() => {
            let total = 11;
            switch (location['pathname'])
            {
                case '/':               function_percentage_set((1/total)*100); break;
                case '/inschatting':    function_percentage_set((1/total)*100); break;
                case '/plaatsing':      function_percentage_set((2/total)*100); break;
                case '/type':           function_percentage_set((3/total)*100); break;
                case '/daktype':        function_percentage_set((4/total)*100); break;
                case '/meterkast':      function_percentage_set((5/total)*100); break;
                case '/merk':           function_percentage_set((6/total)*100); break;
                case '/aantal':         function_percentage_set((7/total)*100); break;
                case '/omvormer':       function_percentage_set((8/total)*100); break;
                case '/contact':        function_percentage_set((9/total)*100); break;
                case '/samenvatting':   function_percentage_set((10/total)*100); break;
                case '/bedankt':        function_percentage_set((11/total)*100); break;

                default:                break;
            }
        }, [location, function_percentage_set]);
        
        return null;
    }

    // Render
    return (
        <HelmetProvider>
            <Helmet>
                <title>{meta_title}</title>
                <meta name="description" content={meta_description} />
                { meta_noindex === true &&
                    <meta name="robots" content="noindex" />
                }
            </Helmet>
            <Router>
                <AppMetatag
                    debug={debug.current}
                    metatag_data={metatag_data} />

                <AppScrollToTop />

                <AppPercentage
                    function_percentage_set={percentage_set} />

                <SectionNavigation
                    debug={debug.current}
                    ip_address={ip_address.current}
                    percentage={percentage}
                    translations_data={translations_nl}
                    upload_images={upload_images.current} />

                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <PageStart
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />

                    <Route
                        exact
                        path="/aantal"
                        element={
                            <PageNumber
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />

                    <Route
                        exact
                        path="/bedankt"
                        element={
                            <PageThankyou
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_images={upload_images.current} />
                        } />

                    <Route
                        exact
                        path="/contact"
                        element={
                            <PageContact
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />

                    <Route
                        exact
                        path="/daktype"
                        element={
                            <PageRooftype
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_icons={upload_icons.current} />
                        } />

                    <Route
                        exact
                        path="/inschatting"
                        element={
                            <PageEstimate
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />
                    
                    <Route
                        exact
                        path="/merk"
                        element={
                            <PageSolarpanel
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_images={upload_images.current} />
                        } />
                    
                    <Route
                        exact
                        path="/meterkast"
                        element={
                            <PageMeterbox
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />
                    
                    <Route
                        exact
                        path="/omvormer"
                        element={
                            <PageInverter
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_icons={upload_images.current} />
                        } />
                    
                    <Route
                        exact
                        path="/plaatsing"
                        element={
                            <PageCalendar
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_icons={upload_icons.current} />
                        } />

                    <Route
                        exact
                        path="/samenvatting"
                        element={
                            <PageOverview
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl} />
                        } />

                    <Route
                        exact
                        path="/type"
                        element={
                            <PageType
                                debug={debug.current}
                                ip_address={ip_address.current}
                                translations_data={translations_nl}
                                upload_icons={upload_icons.current} />
                        } />
                </Routes>
                
                { debug.current === true &&
                    <ElementDebugResponsive />
                }
            </Router>
        </HelmetProvider>
    );
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);