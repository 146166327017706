import React from 'react';

import { SectionCalendar, SectionContact, SectionEstimate, SectionInverter, SectionOverview, SectionMeterbox, SectionNumber, SectionPrevious, SectionRooftype, SectionSolarpanel, SectionStart, SectionThankyou, SectionType } from './Section';

function PageCalendar(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/' 
                translations_data={props.translations_data} />
            
            <SectionCalendar
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageContact(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/omvormer'
                translations_data={props.translations_data} />
            
            <SectionContact
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageEstimate(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/'
                translations_data={props.translations_data} />

            <SectionEstimate
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    )
}

function PageInverter(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/aantal' 
                translations_data={props.translations_data} />

            <SectionInverter
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_images={props.upload_images} />
        </React.Fragment>
    );
}

function PageOverview(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/contact' 
                translations_data={props.translations_data} />

            <SectionOverview
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageNumber(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/merk' 
                translations_data={props.translations_data} />

            <SectionNumber
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageMeterbox(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/daktype' 
                translations_data={props.translations_data} />

            <SectionMeterbox
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageRooftype(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/type' 
                translations_data={props.translations_data} />

            <SectionRooftype
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageSolarpanel(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/meterkast' 
                translations_data={props.translations_data} />

            <SectionSolarpanel
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_images={props.upload_images} />
        </React.Fragment>
    );
}

function PageStart(props)
{
    return (
        <React.Fragment>
            <SectionStart
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />

            <div className="content-copyright">
                ©{new Date().getFullYear()}. Applicatie door <a className="content-copyright-link" href="https://thissen.ai" target="_blank" rel="noreferrer">Thissen AI</a>®
            </div>
        </React.Fragment>
    );
}

function PageThankyou(props)
{
    return (
        <SectionThankyou
            debug={props.debug}
            ip_address={props.ip_address}
            translations_data={props.translations_data}
            upload_images={props.upload_images} />
    );
}

function PageType(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/plaatsing' 
                translations_data={props.translations_data} />

            <SectionType
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

export { PageCalendar, PageContact, PageEstimate, PageInverter, PageOverview, PageMeterbox, PageNumber, PageRooftype, PageSolarpanel, PageStart, PageThankyou, PageType }